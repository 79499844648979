import Api from "@/services/Api";

export default {
  create(params) {
    return Api().post("/api/bani", params);
  },

  list(params) {
    return Api().get("/api/bani", {
      params: params
    });
  },
  delete(id) {
    return Api().delete("/api/bani/" + id);
  }
};
